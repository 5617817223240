export default class Carousel {
  constructor(carouselDotsSelector, slidesSelector) {
    this.carouselDots = document.querySelectorAll(carouselDotsSelector);
    this.slides = document.querySelectorAll(slidesSelector);
  }

  init() {
    const observer = new IntersectionObserver(
      observations => {
        observations.forEach(observation => {
          if (observation.isIntersecting) {
            const index = Array.from(this.slides).indexOf(observation.target);

            this.carouselDots.forEach(dot => {
              dot.classList.remove("bg-ink");
              dot.classList.add("bg-ink-3");
            });

            if (index >= 0 && index < this.carouselDots.length) {
              this.carouselDots[index].classList.remove("bg-ink-3");
              this.carouselDots[index].classList.add("bg-ink");
            }
          }
        });
      },
      {
        threshold: 0.6
      }
    );

    this.slides.forEach(slide => observer.observe(slide));
  }
}
